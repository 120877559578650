














































































































































































































import Vue from "vue";
import SocialAuthenticationBtn from "@/components/auth/SocialAuthenticationBtn.vue";
import { AuthSignup, MobileNumberFields } from "@/interfaces/auth/auth_signup";
import { mapActions, mapGetters } from "vuex";
import {
  AUTH_LOADING,
  GET_USER_DETAILS,
  SIGNUP_USER
} from "@/store/modules/auth/constants";
import { GET_ROLE_BY_NAME } from "@/store/modules/common/constants";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { get_color_by_name } from "@/utils/colors";
import {
  get_password_rules,
  required_rules,
  is_checkbox_selected,
  get_email_rules,
  get_confirm_password_rules
} from "@/utils/global";
export default Vue.extend({
  name: "AuthSignup",
  components: { SocialAuthenticationBtn, VuePhoneNumberInput },
  data(): AuthSignup & {
    valid: boolean;
    touched: boolean;
    phone_number_validity: boolean;
  } {
    return {
      signup_form: {
        name: "",
        mobile_number: "",
        email: "",
        password: "",
        confirm_password: "",
        terms_condition: false
      },
      show_password: false,
      show_confirm_password: false,
      valid: false,
      touched: false,
      phone_number_validity: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      user_data: GET_USER_DETAILS,
      auth_loading: AUTH_LOADING
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    })
  },
  methods: {
    get_password_rules,
    required_rules,
    is_checkbox_selected,
    get_email_rules,
    get_confirm_password_rules,
    get_color_by_name,
    ...mapActions("auth", {
      signup_user: SIGNUP_USER
    }),
    async submit_form() {
      if (!this.signup_form.mobile_number) this.touched = true;
      if (
        (this.$refs?.signup as Vue & { validate: () => boolean })?.validate() &&
        this.phone_number_validity
      ) {
        // Split full name & extracting first & last name
        const split_name = this.signup_form.name.split(" ");
        const first_name = split_name[0];
        let last_name: string | null = null;
        if (split_name.length > 1) last_name = split_name.slice(1).join(" ");
        const obj = {
          first_name: first_name,
          last_name: last_name,
          mobile_number: this.signup_form.mobile_number,
          email: this.signup_form.email,
          password: this.signup_form.password
        };
        const result = await this.signup_user(obj);
        if (result) {
          await this.$router.push({
            name: "verify-email"
          });
        }
      }
    },
    input(v: MobileNumberFields) {
      this.touched = true;
      if (v.isValid) {
        this.phone_number_validity = true;
      } else {
        this.phone_number_validity = false;
      }
    },
    redirect_to_terms_and_condition() {
      // Checkbox remains same when click on the link
      this.signup_form.terms_condition = !this.signup_form.terms_condition;
      window.open("https://hcms.ai/terms-of-use/", "_blank");
    }
  }
});
